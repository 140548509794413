import Vue from 'vue'
export default {
    namespaced: true,
    state: {
        setupProgress: null,
        setupProgressRequired: null,
        setupProgressFull: null,
        redirect: true,
        firstLoad : false,
        deletedConfig: null
    },
    getters: {
        setupProgress: state => {
            return state.setupProgress
        },
        deletedConfig: state => {
            return state.deletedConfig
        },
        redirect: state => {
            return state.redirect
        },
        firstLoad: state => {
            return state.firstLoad
        }
    },
    mutations: {
        SET_SETUP_PROGRESS(state, status) {
            state.setupProgress = status
        },
        SET_DELETED_CONFIG(state, status) {
            state.deletedConfig = status
        },
        SET_REDIRECT(state, data) {
            state.redirect = data
        }
    },
    actions: {
        async getSetupProgress({commit}) {
            const response = await Vue.prototype.$http.get('/api/client/v1/installation_wizard/progress')
                const progress = response.data
                localStorage.setItem('progress', progress)
                commit('SET_SETUP_PROGRESS', progress)
        },
        async setDeletedConfig({commit}) {
            const response = await Vue.prototype.$http.get('/api/client/v1/invoice_config/deleted')
            const deletedConfig = response.data
            commit('SET_DELETED_CONFIG', deletedConfig.show)
        },
        setRedirect({commit}, data) {
            commit('SET_REDIRECT', data)
        }
    }  

}
